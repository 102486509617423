import React, {useEffect, useState} from "react";
import {Table} from "rsuite";
import {ResourceDetailsComponent} from "./right-panel/ResourceDetailsComponent";
import {WeighResource} from "./WeighResource";
import {patchResourceRemainingQuantity} from "../../api/apiResource";
import {MaterialIcon} from "../common/icons/MaterialIcon";

export const ResourcesListTable = (props) => {

    const {colors, brands, technologies, materials, successCallback, errorCallback, materialList,
        dataChange, setRightPartAddIsOpen, setResourceDataToAdd, resourceDataToAdd, printers,
        resourceEmptyWeightsData} = props;

    const { Column, HeaderCell, Cell } = Table;
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [materialListData, setMaterialListData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isWeighOpen, setIsWeighOpen] = useState(false);
    const [weighingResourceId, setWeighingResourceId] = useState(null);
    const [hasDiameter, setHasDiameter] = useState(false);
    const [rightPartDetailsIsOpen, setRightPartDetailsIsOpen] = useState(false);
    // Resource to show in right details panel
    const [selectedResource, setSelectedResource] = useState(null);
    const [selectedReference, setSelectedReference] = useState(null);

    const isFolded = JSON.parse(localStorage.getItem('folded-resources'));

    const getMaterialReferenceForLocalStorage = (resource) => {
        let resourceReference = 'reference_' + resource.material.id + '_' + resource.material.technology.id + resource.brand.id;
        if(resource.color) {
            resourceReference += '_' + resource.color.id;
        }
        return resourceReference;
    }

    // Get list of folded references from local storage
    useEffect(() => {
        let foldedReferences = [];
        if(materialList !== undefined) {
            materialList.materialTypes.map(item => {
                if(item.children && item.children[0]) {
                    let reference = getMaterialReferenceForLocalStorage(item.children[0]);
                    if(!isFolded[reference]) {
                        foldedReferences.push(item.id);
                    }
                }
            });
        }
        setExpandedRowKeys(foldedReferences);
    }, [materialList]);

    useEffect(() => {
        if(materialList !== undefined) {
            setLoading(true);
            setMaterialListData(materialList.materialTypes);
            setHasDiameter(materialList.material.technology.has_diameter);
            setLoading(false);
        }
    }, [materialList, dataChange]);

    useEffect(() => {
        if(resourceDataToAdd !== null) {
            setRightPartAddIsOpen(true);
        }
    }, [resourceDataToAdd]);

    useEffect(() => {
        if(!rightPartDetailsIsOpen) {
            setSelectedResource(null);
            setSelectedReference(null);
        }
    }, [rightPartDetailsIsOpen]);

    /* Sorting */
    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);
        getSortedData(materialListData, sortColumn, sortType)
            .then((data) => {
                setMaterialListData([]);
                setMaterialListData(data);
            }
        );
    };

    const handleRowClick = (data, event) => {
        setSelectedReference(findParentRowData(data));
        if(data.children) {
            // Case parent row : handle table expand
            if(expandedRowKeys.indexOf(data.id) < 0) {
                // Open line
                setExpandedRowKeys(expandedRowKeys => [...expandedRowKeys, data.id]);
                // Update local storage : open line
                if(data.children[0]) {
                    const resourceRef = getMaterialReferenceForLocalStorage(data.children[0]);
                    isFolded[resourceRef] = false;
                    localStorage.setItem('folded-resources', JSON.stringify(isFolded));
                }
            } else {
                // Close line
                setExpandedRowKeys(expandedRowKeys => expandedRowKeys.filter(item => item !== data.id));
                // Update local storage : close line
                if(data.children[0]) {
                    const resourceRef = getMaterialReferenceForLocalStorage(data.children[0]);
                    isFolded[resourceRef] = true;
                    localStorage.setItem('folded-resources', JSON.stringify(isFolded));
                }
            }
        } else {
            // Case child row : show resource details (if click didn't happened on an action button)
            if(!event.target.matches('button') && !event.target.matches('path') && ! event.target.matches('svg')) {
                setSelectedResource(data);
                setRightPartDetailsIsOpen(true);
            }
        }
    }

    /* Find parent data from resource */
    const findParentRowData = (rowData) => {
        for(let i = 0; i < materialList.materialTypes.length; i++) {
            for(let j = 0; j < materialList.materialTypes[i].children.length; j++) {
                if(materialList.materialTypes[i].children[j].id === rowData.id) {
                    return materialList.materialTypes[i];
                }
            }
        }
    }

    const handleAddMaterialFromTableRowClick = (rowData) => {
        setResourceDataToAdd(rowData);
    }

    const getSortedData = (data) => {
        if (sortColumn && sortType) {
            return new Promise((resolve) => {
                resolve (data.sort((a, b) => {
                    // Values to sort
                    let x = a[sortColumn];
                    let y = b[sortColumn];

                    // Case : undefined values
                    if (x === undefined) {
                        return 1;
                    }
                    if (y === undefined) {
                        return -1;
                    }

                    // Get values to compare
                    let valueX = 0;
                    let valueY = 0;
                    if (typeof x === 'string' && typeof y === 'string') {
                        let i = 0;
                        do {
                            valueX = x.charCodeAt(i);
                            valueY = y.charCodeAt(i);
                            i++;
                        } while (valueX === valueY && i < x.length && i < y.length);
                    } else if (typeof x === 'number' && typeof y === 'number') {
                        valueX = x;
                        valueY = y;
                    }

                    if (sortType === 'asc') {
                        return valueX - valueY;
                    } else {
                        return valueY - valueX;
                    }

                }))});
        }
        return new Promise((resolve) => resolve(data));
    };

    const handleWeighButtonClick = (rowData) => {
        setWeighingResourceId(rowData.id);
        setIsWeighOpen(true);
    };

    const handleSetWeightMeasure = (weight) => {
        patchResourceRemainingQuantity(weighingResourceId, weight)
            .then(() => {
                window.location.reload();
                successCallback('The remaining quantity has been updated.');
            })
            .catch(() => {
                errorCallback('Something went wrong.');
            })
    }

    return(
        <div className="main__resources_list_table">
            <ResourceDetailsComponent
                rightPartDetailsIsOpen={rightPartDetailsIsOpen}
                setRightPartDetailsIsOpen={setRightPartDetailsIsOpen}
                colors={colors} brands={brands} materials={materials}
                technologies={technologies} resource={selectedResource}
                successCallback={successCallback} errorCallback={errorCallback}
                printers={printers} selectedReference={selectedReference}
                resourceEmptyWeightsData={resourceEmptyWeightsData}
            />
            {isWeighOpen &&
                <WeighResource
                    successCallback={successCallback}
                    errorCallback={errorCallback}
                    dialogOpen={isWeighOpen} setDialogOpen={setIsWeighOpen}
                    setWeightMeasure={handleSetWeightMeasure}
                />
            }
            <Table data={materialListData} height={450}
                   onRowClick={(data, event) => handleRowClick(data, event)}
                   expandedRowKeys={expandedRowKeys}
                   sortColumn={sortColumn}
                   sortType={sortType}
                   onSortColumn={handleSortColumn}
                   autoHeight={true}
                   isTree
                   rowKey="id"
                   loading={loading}
            >
                <Column flexGrow={6} verticalAlign="middle" sortable>
                    <HeaderCell>Brand - color</HeaderCell>
                    <Cell dataKey="brand_color"/>
                </Column>
                <Column flexGrow={4} verticalAlign="middle" sortable>
                    <HeaderCell>Batch number</HeaderCell>
                    <Cell dataKey="serial_number"/>
                </Column>
                <Column flexGrow={5} verticalAlign="middle" sortable>
                    <HeaderCell>State</HeaderCell>
                    <Cell dataKey="position"/>
                </Column>
                {hasDiameter &&
                    <Column flexGrow={4} verticalAlign="middle" sortable>
                        <HeaderCell>Filament diameter</HeaderCell>
                            <Cell dataKey="filamentDiameter"/>
                    </Column>
                }
                {!hasDiameter &&
                    <Column flexGrow={4} verticalAlign="middle" sortable>
                        <HeaderCell>Informations</HeaderCell>
                        <Cell dataKey="informations">
                            {rowData => {return rowData.children ? '' : '--'}}
                        </Cell>
                    </Column>
                }
                <Column flexGrow={3} verticalAlign="middle" sortable>
                    <HeaderCell>Quantity</HeaderCell>
                    <Cell dataKey="quantity"/>
                </Column>
                <Column flexGrow={4} verticalAlign="middle">
                    <HeaderCell>Actions</HeaderCell>
                    <Cell dataKey="actions">
                        {rowData => {
                            if(rowData.children) {
                                return (
                                    <button data-navigation="right" className="button-link button-table-link" id="resources__add-resource"
                                            onClick={() => handleAddMaterialFromTableRowClick(rowData)}>
                                        Add new
                                    </button>
                                );
                            }
                            else {
                                return (
                                    <div>
                                        <div className="action-buttons">
                                            <button data-navigation="right" className="button-link button-table-link" id="resources__weigh-resource"
                                                    onClick={() => handleWeighButtonClick(rowData)}>
                                                Weight
                                            </button>
                                        </div>
                                    </div>
                                );
                            }
                        }}
                    </Cell>
                </Column>
            </Table>
        </div>
    );
}
