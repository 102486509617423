import React from "react";

export const Help = () => {

    return (
        <>
            <div className="block">
                <h2>Documents to download</h2>

                <ul>
                    <li><a href="https://drive.google.com/file/d/1cOL80x3UWyr3I1F4A7b4Ue8tsP5zox77/view?usp=sharing" target="_blank">User manual</a></li>
                    <li><a href="https://drive.google.com/file/d/14rGte_VXruBM9AsD-aQn1qVwv9mI25u2/view?usp=sharing" target="_blank">Safety brochure</a></li>
                    <li><a href="https://drive.google.com/file/d/1XI3OE0ludgLT4E_8zrKFg3rQGHB_UXKQ/view?usp=sharing" target="_blank">Declaration of conformity</a></li>
                </ul>
            </div>

            <div className="block">
                <h2>Contact</h2>

                <ul>
                    <li><a href="mailto:contact@handddle.com">Talk with Handddle</a></li>
                </ul>
            </div>
        </>
    );
}